.mainButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8004d;
  height: 44px;
  border-radius: 6px;
  margin-top: 20px;
  span {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
}

.title {
  width: 100%;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.container {
  padding: 25px;
  border-radius: 14px;
}

.mainContainer {
  border-radius: 14px;
  .MuiPaper-rounded {
    border-radius: 14px !important;
  }
}
