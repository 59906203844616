.fullscreen {
  outline: none;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  margin: auto;
  border-radius: 15px;
}

.scroll {
  outline: none;
  overflow-y: auto;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  margin: auto;
  border-radius: 15px;
}

.cta {
  outline: none;
  overflow-y: auto;
  width: calc(100% - 50px);
  height: fit-content;
  margin: auto;
  border-radius: 15px;
}

.defaultContent {
  position: relative;
  background-color: white;
  justify-content: center;
  border-radius: 15px;
  padding: 25px;
  overflow-y: auto;
  height: calc(100% - 100px);
  padding-bottom: 55px;
  outline: none;
}

.noDefault {
  background-color: red;
}

.map {
  padding-bottom: 55px;
  margin-top: 15px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(100% - 50px);
  outline: none;
}

.closeModal {
  position: fixed;
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: white;
  bottom: 25px;
  left: 50%;
  height: 44px;
  transform: translate(-50%);
  justify-content: center;
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
  justify-content: center;
  .closeModalIcon {
    cursor: pointer;
  }
}
