.wrapper {
  width: 100%;
  margin-top: 35px;
  min-height: 395px;
  .scale {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .rouletteArrow {
      margin-bottom: 10px;
      svg {
        fill: white;
        width: 25px;
        height: 25px;
      }
    }
    .rouletteCircleArrow {
      margin-top: -10px;
    }
    .roulette {
      border-radius: 50%;
      min-width: 300px;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;
      border: 4px solid white;
      position: relative;
      transform: rotate(-60deg);
      transition: transform 3s cubic-bezier(0.56, -0.16, 0.08, 1);
      &.animation {
        transform: rotate(3660deg);
      }
      .line {
        width: 151px;
        height: 4px;
        background: white;
        position: absolute;
        top: 148px;
        left: 0;
        transform-origin: 100%;
      }
      .line_1 {
        transform: rotate(90deg);
      }
      .line_2 {
        transform: rotate(210deg);
      }
      .line_3 {
        transform: rotate(330deg);
      }
      .circleLogo {
        width: 60px;
        height: 60px;
        position: fixed;
        top: calc(50% - 30px);
        bottom: calc(50% - 30px);
        right: calc(50% - 30px);
        left: calc(50% - 30px);
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(60deg);
        transition: transform 3s cubic-bezier(0.56, -0.16, 0.08, 1);
        svg {
          fill: #1f3084;
          width: 30px;
          height: 30px;
          margin-left: 1px;
        }
      }
    }
    img {
      width: 300px;
      height: 300px;
      position: absolute;
      opacity: 0;
    }
  }
}

@media only screen and (max-width: (350px)) {
  .wrapper {
    margin-top: 0px;
    .scale {
      transform: scale(0.8);
    }
  }
}
