.wrapperContent {
  padding: 16px;

  .content {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;

    .startDate {
      margin-right: 16px;
    }

    .datePicker {
      display: flex;
    }

    .dropDown {
      width: 200px;
      margin-right: 16px;
    }
    .button {
      margin-left: 16px;
    }

    .response {
      width: 100%;
    }
  }
  .catProducts {
    margin-left: 24px;
  }
  .purchaseRange {
    margin-bottom: 16px;
  }
  .packages {
    margin-bottom: 16px;
  }
  .products {
    margin-bottom: 16px;
  }
  .totalProducts {
    margin-left: 24px;
    display: flex;
    flex-direction: row;
  }
  .listProducts {
    margin-left: 24px;
  }
  .summary {
    margin-bottom: 16px;
  }

  .products {
    display: flex;
    flex-direction: column;
  }
  .categoryItems {
    margin-left: 24px;
  }
  .listItems {
    margin-left: 24px;
  }
}
