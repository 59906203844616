.header {
  margin-top: 25px;
  width: 100%;
  min-height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .user {
    width: fit-content;
    min-width: 84px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .profile {
      background-color: #307fe2;
      border: solid 2px white;
      padding: 5px 5px 0 5px;
      margin-left: 8px;
      position: relative;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      &.open {
      }
      .menu {
        position: absolute;
        top: 48px;
        right: -2px;
        background-color: white;
        border-radius: 12px;
        width: fit-content;
        height: 0px;
        overflow: hidden;
        z-index: 999;
        &.open {
          height: fit-content;
          overflow: visible;
          &:before {
            content: " ";
            position: absolute;
            right: 14px;
            top: -10px;
            border-top: none;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid white;
          }
        }
        div {
          white-space: nowrap;
        }
        .session {
          font-family: "Circular Std";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #242426;
          padding: 15px 15px 10px 15px;
        }
        .logout {
          font-family: "Circular Std";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #d8004d;
          padding: 0 15px 15px 15px;
        }
      }
      svg {
        fill: white;
        margin-bottom: -2px;
      }
    }
  }
  .vilalovingLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 110px;
      fill: white;
    }
  }
  .discount {
    width: fit-content;
    min-width: 84px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .clockWrapper {
      position: relative;
      margin-left: 8px;
      .timeRemainingWrapper {
        position: absolute;
        top: 27px;
        right: -5px;
        width: 85px;
        height: 65px;
        overflow: hidden;
        z-index: 999;
        &.closed {
          height: 0px;
        }
        .timeRemaining:before {
          content: " ";
          position: absolute;
          right: 6px;
          top: -10px;
          border-top: none;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
        }
        .timeRemaining {
          position: absolute;
          top: 10px;
          right: 0;
          min-width: fit-content;
          background-color: white;
          padding: 10px;
          border-radius: 6px;
          div,
          span {
            font-family: "Circular Std";
            white-space: nowrap;
            color: #8e8e93;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }
          span {
            color: black;
          }
        }
      }
    }
    .percentage {
      font-family: "Kapra Neue";
      font-weight: 500;
      font-size: 22px;
      line-height: 21px;
      color: #d8004d;
      padding: 10px;
      background-color: white;
      border-radius: 12px;
    }
  }
  .privacyPolicy {
    div {
      margin-bottom: 10px;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: justify;
    }
    div:nth-child(odd) {
      font-family: "Circular Std";
      font-weight: bold;
      color: #242426;
    }
    div:nth-child(even) {
      font-family: "Circular Std Book";
      font-weight: 300;
      color: #5c5c5e;
    }
  }
}
