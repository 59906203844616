.overlay {
  width: 100vw;
  height: 0vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: white;
  -webkit-animation: VILALOVING-ANIMATION 3s 1;
  -moz-animation: VILALOVING-ANIMATION 3s 1;
  -o-animation: VILALOVING-ANIMATION 3s 1;
  animation: VILALOVING-ANIMATION 3s 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    width: 180px;
  }

  span {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
    svg {
      width: 80vw;
    }
    .transitionOne {
      opacity: 0;
      -webkit-animation: TRANSITION-ONE 3s 1;
      -moz-animation: TRANSITION-ONE 3s 1;
      -o-animation: TRANSITION-ONE 3s 1;
      animation: TRANSITION-ONE 3s 1;
    }
    .transitionTwo {
      opacity: 0;
      -webkit-animation: TRANSITION-TWO 3s 1;
      -moz-animation: TRANSITION-TWO 3s 1;
      -o-animation: TRANSITION-TWO 3s 1;
      animation: TRANSITION-TWO 3s 1;
    }
    .transitionThree {
      opacity: 0;
      -webkit-animation: TRANSITION-THREE 3s 1;
      -moz-animation: TRANSITION-THREE 3s 1;
      -o-animation: TRANSITION-THREE 3s 1;
      animation: TRANSITION-THREE 3s 1;
    }
    .transitionFour {
      opacity: 0;
      -webkit-animation: TRANSITION-FOUR 3s 1;
      -moz-animation: TRANSITION-FOUR 3s 1;
      -o-animation: TRANSITION-FOUR 3s 1;
      animation: TRANSITION-FOUR 3s 1;
    }
  }
}

@-webkit-keyframes VILALOVING-ANIMATION {
  0% {
    opacity: 1;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}
@-moz-keyframes VILALOVING-ANIMATION {
  0% {
    opacity: 1;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}
@-o-keyframes VILALOVING-ANIMATION {
  0% {
    opacity: 1;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}
@keyframes VILALOVING-ANIMATION {
  0% {
    opacity: 1;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

@-webkit-keyframes TRANSITION-ONE {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes TRANSITION-ONE {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes TRANSITION-ONE {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes TRANSITION-ONE {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes TRANSITION-TWO {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes TRANSITION-TWO {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes TRANSITION-TWO {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes TRANSITION-TWO {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes TRANSITION-THREE {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes TRANSITION-THREE {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes TRANSITION-THREE {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes TRANSITION-THREE {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes TRANSITION-FOUR {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes TRANSITION-FOUR {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes TRANSITION-FOUR {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes TRANSITION-FOUR {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
