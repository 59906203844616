.completedWrapper {
  height: calc(100vh - 66px - 64px - 12vh);
  height: calc(var(--vh, 1vh) * 100 - 66px - 64px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  & > div:first-child {
    margin-top: auto;
    padding-top: 20px;
  }
  & > div:last-child {
    margin-bottom: auto;
    padding-bottom: 20px;
  }
  .title {
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
  .description {
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.bottomCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  .bottomCTAWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .leave {
      width: 20%;
      display: flex;
      justify-content: center;
      font-family: "Circular Std";
      color: white;
      font-weight: bold;
    }
    .restart {
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      text-transform: unset;
      background-color: #d8004d;
      padding: 12px;
      border-radius: 6px;
      width: 80%;
    }
  }
}
