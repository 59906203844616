.vilaloving {
  width: 100%;
  // Form label styles
  .MuiFormLabel-root {
    color: rgba(255, 255, 255, 1);
    font-family: "Circular Std", "Roboto", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.00938em;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.38);
  }
  .MuiFormLabel-root.Mui-error {
    color: #ff4e8d;
  }
  .MuiFormLabel-colorSecondary.Mui-focused {
    color: #ff4e8d;
  }
  .MuiFormLabel-asterisk.Mui-error {
    color: #ff4e8d;
  }
  // Form input styles
  .MuiInputBase-root {
    color: rgba(255, 255, 255, 1);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Circular Std", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
  }
  .MuiInputBase-root.Mui-error {
    color: #ff4e8d;
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.38);
  }
  // Form input textfield styles
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.75);
  }
  @media (hover: none) {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.75);
    }
  }
  .MuiOutlinedInput-root.Mui-focused:before {
    content: " ";
    overflow: visible;
    position: absolute;
    z-index: 9999;
    left: -18px;
    top: 22px;
    border-right: none;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 10px solid white;
    border-radius: 1px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 1);
    border-width: 2px;
  }
  .MuiOutlinedInput-root.Mui-error:before {
    border-left: 10px solid #ff4e8d;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #ff4e8d;
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.25);
  }
  .MuiOutlinedInput-colorSecondary.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #ff4e8d;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 1);
  }
  // Helper text styles
  .MuiFormHelperText-root {
    color: white;
    margin: 0;
    font-size: 11px;
    min-height: fit-content;
    margin-top: 3px;
    text-align: left;
    font-family: "Circular Std", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  .MuiFormHelperText-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  .MuiFormHelperText-root.Mui-error {
    color: #ff4e8d;
  }
  .MuiFormHelperText-marginDense {
    margin-top: 4px;
  }
  .MuiFormHelperText-contained {
    margin-left: 14px;
    margin-right: 14px;
  }
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
