.box {
  background-color: white;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  padding: 20px;
  height: fit-content;
  font-family: "Poppins", sans-serif;
  color: #14142b;
  border-radius: 32px;
  margin: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  text-align: center;
  word-break: break-all;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  .title {
    font-size: 33px;
    font-weight: 300;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: 1px;
  }
  .value {
    font-size: 33px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .box {
    width: 40%;
  }
}

@media only screen and (max-width: (1024px)) {
  .box {
    width: 80%;
    min-width: 320px;
  }
}
