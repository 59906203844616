.headerLoader {
  position: absolute;
  z-index: -1;
}
.headerWrapper {
  width: 100%;
  height: 525px;
  .headerContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1186px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    .appIconWrapper {
      width: 40%;
      justify-content: center;
      display: flex;
      .appIcon {
        width: 246px;
        background-color: #cecece;
        height: 246px;
        border-radius: 25%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      }
    }
    .downloadWrapper {
      width: 52%;
      .line {
        font-size: 54px;
        font-weight: bold;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        line-height: 0.5em;
        margin: 0;
        background-color: #cecece;
        margin-bottom: 10px;
      }
      .downloadIcons {
        display: flex;
        margin-top: 50px;
        justify-content: space-between;
        flex-direction: row;
        .downloadPlayStore,
        .downloadAppStore {
          text-decoration: none;
          border: 0 none;
          background-color: #cecece;
          width: 264px;
          height: 95px;
        }
        .downloadPlayStore {
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: (1186px)) {
  .header {
    .headerWrapper {
      .headerContent {
        .downloadWrapper {
          .line {
            font-size: 40px;
          }
          .downloadIcons {
            .downloadPlayStore,
            .downloadAppStore {
              img {
                max-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: (959px)) {
  .headerWrapper {
    .headerContent {
      flex-direction: column;
      padding: 10%;
      .appIconWrapper {
        width: 100%;
        margin-bottom: 20px;
        .appIcon {
          width: 100px;
          height: 100px;
        }
      }
      .downloadWrapper {
        width: 100%;
        text-align: center;
        .line {
          font-size: 30px;
          margin-bottom: 20px;
        }
        .downloadIcons {
          display: flex;
          align-items: center;
          flex-direction: column;
          .downloadPlayStore,
          .downloadAppStore {
            width: 80%;
            height: 60px;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
