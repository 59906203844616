.list {
  background-color: white;
  width: 100%;
  min-width: 320px;
  overflow: auto;
  border-radius: 32px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  font-family: "Poppins", sans-serif;
  padding: 20px;
  .title {
    font-size: 38px;
    color: #14142b;
    font-style: normal;
    font-weight: normal;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: 1px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.75px;
      color: #4e4b66;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      tr {
        th {
          padding: 5px;
          text-align: left;
        }
      }
    }
    tbody {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.75px;
      color: #4e4b66;
      tr {
        background-color: white;
        &:nth-child(even) {
          background-color: #eeeded;
        }
        td {
          padding: 5px;
        }
      }
    }
  }
}
