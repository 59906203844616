.content {
  //width: 100%;
  display: flex;
  flex-direction: column;
  .sumupValues {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 24px;
    width: 100%;
  }
  .lists {
    margin-bottom: 24px;
    width: 100%;
  }
}
