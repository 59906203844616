.rouletteWrapper {
  height: calc(100vh - 66px - 64px - 12vh);
  height: calc(var(--vh, 1vh) * 100 - 66px - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  & > div:first-child {
    margin-top: auto;
    padding-top: 20px;
  }
  & > div:last-child {
    margin-bottom: auto;
    padding-bottom: 20px;
  }
  .rouletteDesc {
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    color: white;
    text-align: center;
  }
}
.bottomCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  background-color: #1f3084;
  .bottomCTAWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      text-transform: unset;
      background-color: #d8004d;
      padding: 12px;
      border-radius: 3px;
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.disabled {
        background-color: #d8d8d8;
        color: #a8a8a8;
        svg {
          fill: #a8a8a8;
        }
      }
      svg {
        fill: white;
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
    .again {
      width: 20%;
      display: flex;
      justify-content: center;
      font-family: "Circular Std";
      color: white;
      font-weight: bold;
      svg {
        width: 25px;
        fill: white;
      }
    }
    .continue {
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      text-transform: unset;
      background-color: #d8004d;
      padding: 12px;
      border-radius: 6px;
      width: 80%;
      &.disabled {
        background-color: #d8d8d8;
        color: #a8a8a8;
        svg {
          fill: #a8a8a8;
        }
      }
    }
  }
}

.roulettePlaceholder {
  width: 100%;
  margin-top: 35px;
  min-height: 395px;
}
