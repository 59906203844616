.wrapper {
  height: calc(100vh - 66px - 64px);
  height: calc(var(--vh, 1vh) * 100 - 66px - 64px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  & > div:first-child {
    margin-top: auto !important;
  }
  & > div:last-child {
    margin-bottom: auto !important;
  }
  .block {
    font-family: "Kapra Neue";
    font-style: normal;
    line-height: 24px;
    color: white;
    text-align: center;
  }
  .block:first-child {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 35px;
  }
  .block:nth-child(2) {
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 35px;
  }
  .block:nth-child(3) {
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 35px;
  }
}
.bottomCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  .bottomCTAWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .cta {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 100%;
      background: #d8004d;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
