.experienceWrapper {
  width: 100%;
  height: calc(100vh - 66px - 64px);
  height: calc(var(--vh, 1vh) * 100 - 66px - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-right: 10px;
  .expTitle {
    margin-top: 40px;
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    color: white;
    text-align: center;
  }
  .expDesc {
    margin-top: 50px;
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    color: white;
    text-align: center;
    span:first-child {
      font-size: 24px;
      font-weight: 500;
    }
  }
  .discount {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    margin-top: 25px;
    padding: 20px 25px;
    background-color: #ffffff;
    border-radius: 30px;
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 21px;
    color: #d8004d;
    width: fit-content;
  }
  .cashContainer {
    display: flex;
  }
  .rate {
    margin-top: 50px;
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    color: white;
    text-align: center;
  }
  .rating {
    margin-top: 20px;
  }
  .textArea {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 25px;
  }
  .submit {
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    text-align: center;
    width: 100%;
    color: white;
    margin-top: 50px;
  }
}

.bottomCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  .bottomCTAWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .backButton {
      width: 20%;
      display: flex;
      justify-content: center;
      color: white;
      font-family: "Circular Std Book";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      svg {
        width: 25px;
        fill: white;
      }
    }
    .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-family: "Circular Std Book";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      text-transform: unset;
      background-color: #1f3084;
      padding: 12px;
      border-radius: 6px;
      width: 80%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.animationOverlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
  transform: scale(0);
  opacity: 1;
  background: radial-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.35));
  z-index: 999;
  -webkit-animation: OVERLAY-ANIMATION 3s 1;
  -moz-animation: OVERLAY-ANIMATION 3s 1;
  -o-animation: OVERLAY-ANIMATION 3s 1;
  animation: OVERLAY-ANIMATION 3s 1;
  .animationScale {
    margin-top: -110px;
    margin-left: -8px;
    transform: scale(0);
    -webkit-animation: LOTTIE-ANIMATION 3s 1;
    -moz-animation: LOTTIE-ANIMATION 3s 1;
    -o-animation: LOTTIE-ANIMATION 3s 1;
    animation: LOTTIE-ANIMATION 3s 1;
    animation-timing-function: ease-in-out;
  }
}

@-webkit-keyframes LOTTIE-ANIMATION {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-moz-keyframes LOTTIE-ANIMATION {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-o-keyframes LOTTIE-ANIMATION {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes LOTTIE-ANIMATION {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@-webkit-keyframes OVERLAY-ANIMATION {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-moz-keyframes OVERLAY-ANIMATION {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-o-keyframes OVERLAY-ANIMATION {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes OVERLAY-ANIMATION {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
