.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 24px;
  .icon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .category {
    margin-top: 40px;
    p {
      margin: 24px 0;
    }
    .title {
      margin-top: 0px;
      font-size: 16px;
      width: 100%;
      text-align: center;
    }
    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 280px;
    }
  }
  .options {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .button {
      width: 100%;
      max-width: 280px;
      background-color: #2c2b31;
      color: white;
      margin: 5px;
      padding: 12px;
      font-weight: bold;
      text-transform: none;
      min-height: 72px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

@media only screen and (max-width: (991px)) {
}
