.header {
  width: 100%;
  height: 525px;
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  .headerWrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    .headerContent {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 1186px;
      width: 100%;
      margin: auto;
      .appIconWrapper {
        width: 40%;
        justify-content: center;
        display: flex;
        .appIcon {
          height: 246px;
          border-radius: 25%;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        }
      }
      .downloadWrapper {
        width: 52%;
        .mainTitle {
          font-size: 54px;
          color: #ffffff;
          font-weight: bold;
          text-shadow: -2px 1px 2px rgba(0, 0, 0, 0.3);
          line-height: 1.3em;
          margin: 0;
        }
        .downloadIcons {
          .downloadPlayStore,
          .downloadAppStore {
            text-decoration: none;
            border: 0 none;
            img {
              max-width: 264px;
            }
          }
          .downloadPlayStore {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
.content {
  padding: 80px 0;
  .leftSide {
    text-align: center;
    .slideImage {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 400px;
        width: 90%;
        border-radius: 45px;
      }
    }
  }
  .rightSide {
    padding: 0 20px;
    box-sizing: border-box;
    .contentText {
      h2 {
        font-size: 35px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 5px;
        text-align: left;
      }
      p {
        margin: 0 0 10px;
        font-size: 16px;
      }
    }
    .qrWrapper {
      display: flex;
      margin: 40px auto 0;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .qrImage {
        width: 184px;
        height: 184px;
      }
      .qrText {
        width: 40%;
        padding: 20px 0 0 20px;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

.socketWrapper {
  background-color: rgb(17, 17, 17);
  padding: 10px 0;
  .poweredBySpotlio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    span {
      color: #ffffff;
      padding-right: 10px;
    }
    a {
      text-decoration: none;
      img {
        display: block;
        width: 87px;
      }
    }
  }
}

@media only screen and (max-width: (1186px)) {
  .header {
    .headerWrapper {
      .headerContent {
        .downloadWrapper {
          .mainTitle {
            font-size: 40px;
          }
          .downloadIcons {
            .downloadPlayStore,
            .downloadAppStore {
              img {
                max-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: (959px)) {
  .header {
    .headerWrapper {
      .headerContent {
        flex-direction: column;
        padding: 10%;
        .appIconWrapper {
          width: 100%;
          margin-bottom: 20px;
          .appIcon {
            height: 100px;
          }
        }
        .downloadWrapper {
          width: 100%;
          text-align: center;
          .mainTitle {
            font-size: 30px;
            margin-bottom: 20px;
          }
          .downloadIcons {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .downloadPlayStore {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
