.textareaWrapper {
  width: calc(100% - 52px);
  padding: 25px;
  background-color: white;
  border-radius: 30px;
  .textarea {
    width: 100%;
    font-family: "Circular Std Book";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8e8e93;
    outline: none !important;
    resize: none;
    border: unset;
    box-shadow: unset;
    padding: 0 0 20px 0;
  }
  .submitButton {
    background: #1f3084;
    border-radius: 6px;
    width: 100%;
    font-family: "Circular Std Book";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    cursor: pointer;
  }
}
