.content {
    background: #fff;
    box-shadow: 0 2px 60px 0 rgba(6, 135, 204, 0.3);
    margin: auto;
    margin-top: 50px;
    text-align: center;
    padding: 75px;
    outline: none;
    border-radius: 10px;
    overflow-y: auto;
    position: relative;
    width: fit-content;
    &.bigModal {
      min-width: 860px;
      max-width: 1280px;
      height: calc(100vh - 100px);
    }
    .closeModal {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .DatePicker {
      width: 100%;
      .DateRangePicker {
        width: 100%;
      }
    }
  }
  .oldModal {
    background: #f4f5f6;
    box-shadow: 0 2px 60px 0 rgba(6, 135, 204, 0.3);
    margin: auto;
    margin-top: 50px;
    text-align: center;
    padding: 30px 75px;
    outline: none;
    border-radius: 10px;
    overflow-y: auto;
    max-width: 1280px;
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    position: relative;
    width: 860px;
    &.smallModal {
      height: unset;
    }
    .closeModal {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .DatePicker {
      width: 100%;
      .DateRangePicker {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: (991px)) {
    .content {
      &.bigModal {
        height: 100vh;
        min-width: unset;
      }
      min-width: unset;
      width: 100vw;
      height: 100vh;
      padding: 20px;
      border-radius: 0;
      margin-top: 0;
      overflow-y: scroll;
      padding-top: 60px;
      max-height: unset;
    }
    .oldModal {
      &.smallModal {
        height: 100vh;
      }
      width: 100vw;
      height: 100vh;
      padding: 20px;
      border-radius: 0;
      margin-top: 0;
      overflow-y: scroll;
      padding-top: 60px;
      max-height: unset;
    }
  }
  