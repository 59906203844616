.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupTitle {
  font-family: "Circular Std Book";
}

.popupButton {
  background-color: white;
  border: 1px solid black;
}
