.formWrapper {
  height: calc(100vh - 66px - 96px - 12vh);
  height: calc(var(--vh, 1vh) * 100 - 66px - 96px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  & > div:first-child {
    margin-top: auto;
    padding-top: 20px;
  }
  & > div:last-child {
    margin-bottom: auto;
    padding-bottom: 20px;
  }
  .formDescription {
    font-family: "Kapra Neue";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    color: white;
    text-align: center;
    margin-bottom: 35px;
  }
  .formInputs {
    padding: 0 20px;
    .input {
      margin: 20px 0;
    }
  }
}
.bottomCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 96px;
  background-color: #1f3084;
  .registered {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    text-align: center;
    font-family: "Circular Std Book";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: white;
    cursor: pointer;
    b {
      font-family: "Circular Std";
      font-weight: 900;
    }
  }
  .bottomCTAWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-family: "Circular Std";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      text-transform: unset;
      background-color: #d8004d;
      padding: 12px;
      border-radius: 3px;
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.disabled {
        background-color: #f4f5f6;
        color: #d3d3d3;
      }
    }
  }
}

.error {
  color: #ff4e8d;
  font-family: "Circular Std";
  font-size: 12px;
}
