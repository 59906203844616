.carousel {
  position: relative;
  max-height: 100%;
  .BrainhubCarousel__container {
    max-height: 100%;
  }
  div,
  li,
  img {
    height: 100%;
  }
  .BrainhubCarousel {
    li {
      height: fit-content;
      img {
        width: 100%;
        display: block;
        border-radius: 3px;
      }
    }
    &:hover {
      .BrainhubCarousel__custom-arrowLeft,
      .BrainhubCarousel__custom-arrowRight {
        svg {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s, visibility 0.3s;
          cursor: pointer;
        }
      }
    }
    .BrainhubCarousel__custom-arrowLeft {
      svg {
        cursor: pointer;
        padding: 5px;
        width: 28px;
        height: 28px;
        position: absolute;
        left: 10px;
        top: calc(50% - 12px);
        background-color: #ffffff;
        z-index: 2;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
      }
    }
    .BrainhubCarousel__custom-arrowRight {
      svg {
        cursor: pointer;
        padding: 5px;
        width: 28px;
        height: 28px;
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
        background-color: #ffffff;
        z-index: 2;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
      }
    }
  }
}
